import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n'
import { configure } from 'vee-validate';

import './plugins'

Vue.config.productionTip = false;
Vue.prototype.$app_country = process.env.VUE_APP_COUNTRY;
Vue.prototype.$app_currency_code = process.env.VUE_APP_CURRENCY_CODE;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')

const locale = store.getters['lang/locale'];
i18n.locale = locale; // important!
vuetify.framework.lang.current = locale;

configure({
  // this will be used to generate messages.
  defaultMessage: (field, values) => {
    // values._field_ = i18n.t(`fields.${field}`);
    values._field_ = i18n.t(`${field}`);
    if (values.target) {
      values.target = i18n.t(`${values.target}`);
    }
    // console.log(values) //eslint-disable-line
    return i18n.t(`validations.messages.${values._rule_}`, values);
  }
});
