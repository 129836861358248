import axios from 'axios'
import store from '../store'
import router from '../router'
// import Swal from 'sweetalert2'
// import i18n from '../plugins/i18n'
import * as global from '../constants/global'

axios.defaults.baseURL = process.env.NODE_ENV === 'production'
    ? global.LIVE_URL
    : '';

// axios.defaults.baseURL = 'http://api.jesusx.site:8080/';
axios.defaults.withCredentials = true;

// Request interceptor
axios.interceptors.request.use(request => {
  const token = store.getters['auth/token']
  if (token) {
    request.headers.common['Authorization'] = `Bearer ${token}`
  }

  const locale = store.getters['lang/locale']
  if (locale) {
    request.headers.common['Accept-Language'] = locale
  }

  // request.headers['X-Socket-Id'] = Echo.socketId()

  return request
})

// Response interceptor
axios.interceptors.response.use(response => response, error => {
  const { status } = error.response

  if (error.response.hasOwnProperty('data') && error.response.data.hasOwnProperty('code')) {
    const { code } = error.response.data

    if (code === 11) {
      //   Swal.fire({yarn
      //     type: 'warning',
      //     title: i18n.t('token_expired_alert_title'),
      //     text: i18n.t('token_expired_alert_text'),
      //     reverseButtons: true,
      //     confirmButtonText: i18n.t('ok'),
      //     cancelButtonText: i18n.t('cancel')
      //   }).then(() => {
      store.commit('auth/LOGOUT')

      router.push({ name: 'signin' })
      //   })
    }
  }


  // const { code } = error.response.data

  if (status >= 500) {
    // Swal.fire({
    //   type: 'error',
    //   title: i18n.t('error_alert_title'),
    //   text: i18n.t('error_alert_text'),
    //   reverseButtons: true,
    //   confirmButtonText: i18n.t('ok'),
    //   cancelButtonText: i18n.t('cancel')
    // })
  }

  if (status === 401 && store.getters['auth/check']) {
    // Swal.fire({
    //   type: 'warning',
    //   title: i18n.t('token_expired_alert_title'),
    //   text: i18n.t('token_expired_alert_text'),
    //   reverseButtons: true,
    //   confirmButtonText: i18n.t('ok'),
    //   cancelButtonText: i18n.t('cancel')
    // }).then(() => {
    //   store.commit('auth/LOGOUT')
    //
    //   router.push({ name: 'login' })
    // })
  }

  return Promise.reject(error)
})
