import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import lang from './modules/lang'
import snackbar from './modules/snackbar'
import loading from './modules/loading'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    lang,
    snackbar,
    loading
  },
  plugins: [createPersistedState({
    paths: ['lang'],
  })],
})
