export const UserStatus = {
    FIRST_CREATED: 0,
    ACTIVE: 1,
    DEACTIVATED: 2,
    DELETED: 99
};

export const UserType = {
    ROOT: 0,
    MANAGER: 1,
    CS_AGENT: 2,
    CLIENT: 3,
    CLIENT_CSA: 4,
    REPORTER: 5,
    CS_AGENT_BOT: 6,
};
