import * as types from '../mutation-types'

// state
export const state = {
  snackbar: null,
  color: null,
  message: null
};

// getters
export const getters = {
  snackbar: state => state.snackbar,
  color: state => state.color,
  message: state => state.message
};

// mutations
export const mutations = {
  [types.UPDATE_SNACKBAR](state, {snackbar}) {
    state.snackbar = snackbar;
  },
  [types.OPEN_SNACKBAR](state, {message, color}) {
    state.snackbar = true;
    state.message = message;
    state.color = color;
  },
  [types.CLOSE_SNACKBAR](state) {
    state.snackbar = null;
  },
};

// actions
export const actions = {
  // saveToken ({ commit, dispatch }, payload) {
  openSnackbar({commit}, payload) {
    commit(types.OPEN_SNACKBAR, payload)
  },
  update({commit}, payload) {
    commit(types.UPDATE_SNACKBAR, payload)
  },
  close({commit}) {
    commit(types.CLOSE_SNACKBAR)
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
