// auth.js
export const LOGOUT = 'LOGOUT'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_API_SECRET = 'UPDATE_USER_API_SECRET'
export const UPDATE_WORKING_MODE = 'UPDATE_WORKING_MODE'
export const UPDATE_ASSIGNMENT = 'UPDATE_ASSIGNMENT'
export const ADD_ASSIGNMENT = 'ADD_ASSIGNMENT'
export const REMOVE_ASSIGNMENT = 'REMOVE_ASSIGNMENT'
export const UPDATE_UNASSIGNED_TASKS = 'UPDATE_UNASSIGNED_TASKS'
export const ADD_UNASSIGNED_TASKS = 'ADD_UNASSIGNED_TASKS'
export const REMOVE_UNASSIGNED_TASKS = 'REMOVE_UNASSIGNED_TASKS'
export const UPDATE_SAFETY_DIALOG = 'UPDATE_SAFETY_DIALOG'
export const UPDATE_EDITING_TASK = 'UPDATE_EDITING_TASK'
export const SYS_ERR_LOG_MUTABLE = 'SYS_ERR_LOG_MUTABLE'
export const SYS_ERR_LOG_NEW_ITEM = 'SYS_ERR_LOG_NEW_ITEM'

// snackbar.js
export const UPDATE_SNACKBAR = 'UPDATE_SNACKBAR'
export const OPEN_SNACKBAR = 'OPEN_SNACKBAR'
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR'

// loading.js
export const UPDATE_NAVBAR_LOADING = 'UPDATE_NAVBAR_LOADING'

// lang.js
export const SET_LOCALE = 'SET_LOCALE'

