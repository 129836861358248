<template>
  <v-container
  >
    <v-row
      v-if="isClient"
    >
      <v-col cols="12" class="pb-0 heading">
        {{$t('your_api_credentials')}}
      </v-col>
      <v-col
        cols="12"
        md="3"
        sm="6"
      >
        <v-text-field
          outlined
          hide-details
          label="API Key"
          ref="apiKey"
          append-outer-icon="mdi-content-copy"
          @click:append-outer="copyApiKey"
          readonly
          v-model="user.api_key"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="3"
        sm="6"
      >
        <v-text-field
          outlined
          hide-details
          label="API Secret"
          ref="apiSecret"
          :type="viewSecret ? 'text' : 'password'"
          append-icon="mdi-eye"
          @click:append="viewSecret = !viewSecret"
          append-outer-icon="mdi-content-copy"
          @click:append-outer="copyApiSecret"
          readonly
          v-model="user.api_secret"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="3"
        sm="6"
        class="my-auto"
      >
        <v-btn
          color="primary"
          :loading="regenApiBusy"
          @click.stop="apiDialog = true"
        >
          {{$t('regen_secret')}}
          <template>
            <v-dialog
              v-model="apiDialog"
              max-width="290"
            >
              <v-card>
                <v-card-title class="headline">{{$t('confirm_title')}}</v-card-title>

                <v-card-text>
                  {{$t('regen_secret_confirmation')}}
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    text
                    :loading="regenApiBusy"
                    @click="apiDialog = false"
                  >
                    {{$t('cancel')}}
                  </v-btn>

                  <v-btn
                    color="primary"
                    :loading="regenApiBusy"
                    @click="regenApiSecret"
                  >
                    {{$t('continue')}}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row
      v-if="isClient"
      align="center"
    >
      <v-col cols="3">
        <v-card :to="{name: 'home'}">
          <v-list disabled>
            <v-list-item-group color="primary">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon x-large>mdi-account-tie</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle>{{$t('cs_agent_count')}}</v-list-item-subtitle>
                  <v-list-item-content>{{overview.client_csa_count}}</v-list-item-content>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <v-row
      v-if="isRoot || isManager"
      align="center"
      justify="center"
    >
      <v-col>
        <v-card :to="{name: 'home'}">
          <v-list disabled>
            <v-list-item-group color="primary">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon x-large>mdi-account-tie</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle>{{$t('manager_count')}}</v-list-item-subtitle>
                  <v-list-item-content>{{overview.manager_count}}</v-list-item-content>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col>
        <v-card :to="{name: 'home'}">
          <v-list disabled>
            <v-list-item-group color="primary">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon x-large>mdi-account-hard-hat</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle>{{$t('cs_agent_count')}}</v-list-item-subtitle>
                  <v-list-item-content>{{overview.cs_agent_count}}</v-list-item-content>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col>
        <v-card :to="{name: 'home'}">
          <v-list disabled>
            <v-list-item-group color="primary">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon x-large>mdi-account-cash</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle>{{$t('client_count')}}</v-list-item-subtitle>
                  <v-list-item-content>{{overview.client_count}}</v-list-item-content>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col>
        <v-card :to="{name: 'home'}">
          <v-list disabled>
            <v-list-item-group color="primary">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon x-large>mdi-credit-card</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle>{{$t('bank_card_count')}}</v-list-item-subtitle>
                  <v-list-item-content>{{overview.bank_card_count}}</v-list-item-content>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col>
        <v-card :to="{name: 'home'}">
          <v-list disabled>
            <v-list-item-group color="primary">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon x-large>mdi-api</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle>{{$t('active_gateway')}}</v-list-item-subtitle>
                  <v-list-item-content>{{overview.active_gateway}}</v-list-item-content>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <v-row v-if="authenticated">
      <v-col>
        <v-card>
          <v-tabs v-model="selected_transaction" class="mt-2">
            <v-tab v-for="trx in availableTransactionTypes" :key="trx">
              {{$t(getKeyByValue(TransactionType, trx))}}
            </v-tab>

            <v-tabs-items v-model="selected_transaction">
              <v-tab-item v-for="trx in availableTransactionTypes" :key="trx">
                <v-row align="center" justify="center">
                  <v-col>
                    <v-row>
                      <v-col>
                        <v-card flat>
                          <v-row
                            align="center"
                            justify="center"
                          >
                            <v-col>
                              <v-card flat>
                                <v-list disabled>
                                  <v-list-item-group color="primary">
                                    <v-list-item>
                                      <v-list-item-icon>
                                        <v-icon x-large>mdi-file-clock</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-subtitle>{{$t( 'pending_transaction_count')}}</v-list-item-subtitle>
                                        <v-list-item-content>{{overview.transaction[trx]['pending_transaction_count']}}</v-list-item-content>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list-item-group>
                                </v-list>
                              </v-card>
                            </v-col>
                            <v-col>
                              <v-card flat>
                                <v-list disabled>
                                  <v-list-item-group color="primary">
                                    <v-list-item>
                                      <v-list-item-icon>
                                        <v-icon x-large>mdi-credit-card-clock</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-subtitle>{{$t('pending_transaction_amount')}}</v-list-item-subtitle>
                                        <v-list-item-content>{{overview.transaction[trx]['pending_transaction_amount']}}</v-list-item-content>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list-item-group>
                                </v-list>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-tabs v-model="time_window">
                      <v-tab v-for="time in time_windows" :key="time.name">{{time.text}}</v-tab>

                      <v-tabs-items v-model="time_window">
                        <v-tab-item
                          v-for="time in time_windows"
                          :key="time.tab"
                        >
                          <v-card>
                            <v-row
                              align="center"
                              justify="center"
                            >
                              <v-col>
                                <v-card flat>
                                  <v-list disabled>
                                    <v-list-item-group color="primary">
                                      <v-list-item>
                                        <v-list-item-icon>
                                          <v-icon x-large>mdi-file-check</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                          <v-list-item-subtitle>{{$t( 'completed_transaction_count')}}</v-list-item-subtitle>
                                          <v-list-item-content>{{overview.transaction[trx][time.name + '_completed_transaction_count']}}</v-list-item-content>
                                        </v-list-item-content>
                                      </v-list-item>
                                    </v-list-item-group>
                                  </v-list>
                                </v-card>
                              </v-col>
                              <v-col>
                                <v-card flat>
                                  <v-list disabled>
                                    <v-list-item-group color="primary">
                                      <v-list-item>
                                        <v-list-item-icon>
                                          <v-icon x-large>mdi-credit-card-check</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                          <v-list-item-subtitle>{{$t('completed_transaction_amount')}}</v-list-item-subtitle>
                                          <v-list-item-content>{{overview.transaction[trx][time.name + '_completed_transaction_amount']}}</v-list-item-content>
                                        </v-list-item-content>
                                      </v-list-item>
                                    </v-list-item-group>
                                  </v-list>
                                </v-card>
                              </v-col>
                              <v-col v-if="isRoot || isManager">
                                <v-card flat>
                                  <v-list disabled>
                                    <v-list-item-group color="primary">
                                      <v-list-item>
                                        <v-list-item-icon>
                                          <v-icon x-large>mdi-cash</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                          <v-list-item-subtitle>{{$t('completed_transaction_commission')}}</v-list-item-subtitle>
                                          <v-list-item-content>{{overview.transaction[trx][time.name + '_completed_transaction_commission']}}</v-list-item-content>
                                        </v-list-item-content>
                                      </v-list-item>
                                    </v-list-item-group>
                                  </v-list>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-tab-item>
                      </v-tabs-items>
                    </v-tabs>
                  </v-col>
                  <v-col>
                    <v-card flat>
                      <v-sheet
                        class="v-sheet--offset mx-auto"
                        color="primary"
                        elevation="12"
                        max-width="calc(100% - 32px)"
                      >
                        <v-sparkline
                          :labels="labels"
                          :value="value"
                          color="white"
                          line-width="2"
                          padding="16"
                        ></v-sparkline>
                      </v-sheet>

                      <v-card-text class="pt-0">
                        <div class="title font-weight-light mb-2">{{$t('total_amount')}}</div>
                        <div class="subheading font-weight-light grey--text">{{$t('performance')}}</div>
                        <v-divider class="my-2"></v-divider>
                        <v-icon
                          class="mr-2"
                          small
                        >
                          mdi-clock
                        </v-icon>
                        <span class="caption grey--text font-weight-light">{{$t('last_task_ago', {minute: 0})}}</span>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex'
import axios from 'axios'
import transactionMixins from '../mixins/transactionMixins'

export default {
  name: 'home',
  mixins: [transactionMixins],
  computed: {
    ...mapGetters({
      user: 'auth/user',
      authenticated: 'auth/check',
      isRoot: 'auth/isRoot',
      isManager: 'auth/isManager',
      isCsAgent: 'auth/isCsAgent',
      isClient: 'auth/isClient',
    }),
    time_windows() {
      return [
        {
          name: 'day',
          text: this.$t('Daily'),
        },
        {
          name: 'month',
          text: this.$t('Monthly'),
        },
        {
          name: 'year',
          text: this.$t('Yearly'),
        },
      ];
    },
  },
  mounted () {
    this.fetchOverview();

    this.availableTransactionTypes = Object.assign({}, this.TransactionType);
    delete this.availableTransactionTypes[this.getKeyByValue(this.TransactionType, this.TransactionType.ADJUSTMENT)];
  },
  data: function () {
    return {
      availableTransactionTypes: [],
      selected_transaction: {
        code: 0,
        text: this.$t('topup')
      },
      time_window: 'day',
      apiDialog: false,
      regenApiBusy: false,
      viewSecret: false,
      labels: [
        '12am',
        '3am',
        '6am',
        '9am',
        '12pm',
        '3pm',
        '6pm',
        '9pm',
      ],
      value: [
        888,
        888,
        888,
        888,
        888,
        888,
        888,
        888,
      ],
      overview: {
        "transaction": {
          "0": {
            "pending_transaction_count": 8,
            "pending_transaction_amount": 888.8,
            "day_completed_transaction_count": 8,
            "day_completed_transaction_amount": 8,
            "month_completed_transaction_count": 8,
            "month_completed_transaction_amount": 8,
            "year_completed_transaction_count": 8,
            "year_completed_transaction_amount": 8,
            "day_completed_transaction_commission": 8,
            "month_completed_transaction_commission": 8,
            "year_completed_transaction_commission": 8
          },
          "1": {
            "pending_transaction_count":  8,
            "pending_transaction_amount": 8,
            "day_completed_transaction_count": 8,
            "day_completed_transaction_amount": 8,
            "month_completed_transaction_count": 8,
            "month_completed_transaction_amount": 8,
            "year_completed_transaction_count": 8,
            "year_completed_transaction_amount": 8,
            "day_completed_transaction_commission": 8,
            "month_completed_transaction_commission": 8,
            "year_completed_transaction_commission": 8
          },
          "2": {
            "pending_transaction_count":  8,
            "pending_transaction_amount": 8,
            "day_completed_transaction_count": 8,
            "day_completed_transaction_amount": 8,
            "month_completed_transaction_count": 8,
            "month_completed_transaction_amount": 8,
            "year_completed_transaction_count": 8,
            "year_completed_transaction_amount": 8,
            "day_completed_transaction_commission": 8,
            "month_completed_transaction_commission": 8,
            "year_completed_transaction_commission": 8
          },
          "3": {
            "pending_transaction_count":  8,
            "pending_transaction_amount": 8,
            "day_completed_transaction_count": 8,
            "day_completed_transaction_amount": 8,
            "month_completed_transaction_count": 8,
            "month_completed_transaction_amount": 8,
            "year_completed_transaction_count": 8,
            "year_completed_transaction_amount": 8,
            "day_completed_transaction_commission": 8,
            "month_completed_transaction_commission": 8,
            "year_completed_transaction_commission": 8
          }
        },
        "manager_count": 8,
        "cs_agent_count": 8,
        "client_count": 8,
        "bank_card_count": 8,
        "active_gateway": 8,
        "client_csa_count": 8,
      }
    }
  },
  methods: {
    async fetchOverview(){
      ///app/api/overview
      this.busy = true;
      const _this = this;
      await axios.get('/app/api/overview')
        .then(function (response) {
          // console.log(response.data.data); //eslint-disable-line
          _this.overview = response.data.data;
        })
        .catch(function (error) {
          _this.$store.dispatch('snackbar/openSnackbar', {
            message: error.response.data.message,
            color: 'error',
          })
        })
        .finally(function () {
          _this.busy = false;
        })
    },
    async regenApiSecret () {
      this.regenApiBusy = true
      const _this = this

      await axios.post(`/app/api/client/${this.user.user_id}/chgapisecret`)
        .then(function (response) {
          _this.$store.dispatch('auth/updateUserApiSecret', response.data.data);
          _this.apiDialog = false

          _this.$store.dispatch('snackbar/openSnackbar', {
            message: response.data.message,
            color: 'success',
          })
        })
        .catch(function (error) {
          _this.$store.dispatch('snackbar/openSnackbar', {
            message: error.response.data.message,
            color: 'error',
          })
        })
        .finally(function () {
          _this.regenApiBusy = false;
        })
    },
    copyApiKey() {
      this.copyToClipboard(this.user.api_key, 'API Key')
    },
    copyApiSecret() {
      this.copyToClipboard(this.user.api_secret, 'API Secret')
    },
    copyToClipboard(value, type) {
      navigator.clipboard.writeText(value).then(() => {
        this.$store.dispatch('snackbar/openSnackbar', {
          message: this.$t('copy_success', [type]),
          color: 'success',
        })
      });
    },
  }
}
</script>
