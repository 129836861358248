import * as global from '../constants/global'

let globalMixins = {
  data: () => ({
    apiEndpoint: process.env.NODE_ENV === 'production'
      ? global.LIVE_URL
      : global.DEV_URL,
    wsEndpoint: process.env.NODE_ENV === 'production'
      ? global.LIVE_WS_URL
      : global.DEV_WS_URL,
    appName: global.APP_NAME,
  }),
}

export default globalMixins;
