import store from '../store'

export default (to, from, next, roles) => {
  // for (var role of store.getters['auth/roles']) {
  //   if (role === 'admin') {
  //     next()
  //   }
  // }
  // next({ name: 'welcome' })
  const user = store.getters['auth/user']
  if (user && roles.includes(user.user_type)) {
    next()
  } else {
    next({name: 'signin'})
  }
}
