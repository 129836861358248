import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import DatetimePicker from 'vuetify-datetime-picker'

// Translation provided by Vuetify (javascript)
import en from 'vuetify/es5/locale/en'
import zh_CN from 'vuetify/es5/locale/zh-Hans'
import i18n from './i18n'

Vue.use(Vuetify);
Vue.use(DatetimePicker)

export default new Vuetify({
  rtl: false,
  theme: {
    dark: false,
    themes: {
      dark: {
        primary: '#00838F',
        accent: '#CE93D8',
        secondary: '#0277BD',
        success: '#4CAF50',
        info: '#2196F3',
        warning: '#FB8C00',
        error: '#FF5252'
      },
      light: {
        primary: '#00838F',
        accent: '#CE93D8',
        secondary: '#0277BD',
        success: '#4CAF50',
        info: '#2196F3',
        warning: '#FB8C00',
        error: '#FF5252'
      }
    }
  },
  lang: {
    locales: {zh_CN, en},
    current: i18n.locale,
  },
});
