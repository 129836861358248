export const TransactionStatus = {
  PENDING: 0,
  COMPLETED: 1,
  REJECTED: 2
};

export const TransactionType = {
  TOP_UP: 0,
  WITHDRAW: 1,
  COLLECT: 2,
  PAY: 3,
  ADJUSTMENT: 4,
};

export const CollectBotSts = {
  PENDING: 0,         // New transaction
  SEL_BANK: 1,        // Selected bank
  ENTER_LOGIN: 2,     // Entered login and credential
  ENTER_AUTH: 3,      // Entered authorization token (e.g. OTP)
  COMPLETED: 4,       // Completed transaction
  REJECTED: 5,        // Rejected transaction
};

export const AuthMethod = {
  NONE: 0,
  OTP: 1,
  APP_AUTH: 2,
  AUTH_TOKEN: 3,
};

export const P2pSts = {
    PENDING: 0,         // New transaction
    SEL_BANK: 1,        // Selected bank
    COMPLETED: 2,       // Completed transaction
    REJECTED: 3,        // Rejected transaction
};