import * as trx from '../constants/transaction'

let transactionMixins = {
  data: () => ({
    TransactionStatus: trx.TransactionStatus,
    TransactionType: trx.TransactionType,
    CollectBotSts: trx.CollectBotSts,
    P2pSts: trx.P2pSts,
  }),
  methods: {
    getKeyByValue(object, value, fallback = null) {
      const key = Object.keys(object).find(key => object[key] === value)
      return key ? key : fallback;
    },
    getStatusColor (status_type, assignee) {
      if (status_type === this.TransactionStatus.PENDING) {
        if (assignee) {
          return 'orange--text text--darken-1';
        } else {
          return'grey--text text--darken-1';
        }
      } else  if (status_type === this.TransactionStatus.COMPLETED){
        return 'green--text text--darken-1';
      } else  if (status_type === this.TransactionStatus.REJECTED){
        return 'red--text text--darken-1';
      }
      return 'red--text text--darken-1';
    },
    getCollectBotStsColor (collectb_sts) {
      if (collectb_sts === this.CollectBotSts.COMPLETED){
        return 'text-input-green';
      } else  if (collectb_sts === this.CollectBotSts.REJECTED){
        return 'text-input-red';
      }
      return '';
    },
    getStatusType (status_type, assignee) {
      if (status_type === this.TransactionStatus.PENDING) {
        if (assignee) {
          return this.$t('in_review');
        } else {
          return this.$t('pending');
        }
      } else  if (status_type === this.TransactionStatus.COMPLETED){
        return this.$t('success');
      } else  if (status_type === this.TransactionStatus.REJECTED){
        return this.$t('failed');
      }
      return this.$t('error');
    },
    getP2pStsColor (p2p_sts) {
      if (p2p_sts === this.P2pSts.COMPLETED){
        return 'text-input-green';
      } else  if (p2p_sts === this.P2pSts.REJECTED){
        return 'text-input-red';
      }
      return '';
    },
    getCallbackStsColor (success) {
      return success ? 'text-input-green' : 'text-input-red';
    },
  }
}

export default transactionMixins;
