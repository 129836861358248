<template>
  <v-app>
    <router-view></router-view>
    <v-snackbar
      v-model="snackbar"
      :multi-line="true"
    >
      {{ snackbarMsg }}
      <v-btn
        :color="snackbarColor"
        text
        @click="snackbar = false"
      >
        {{$t('close')}}
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
  import { mapGetters } from 'vuex'
  import globalMixins from './mixins/globalMixins'

  export default {
    name: 'App',
    metaInfo () {
      return {
        title: '',
        titleTemplate: this.appName,
      }
    },
    mixins: [globalMixins],
    computed: {
      ...mapGetters({
        snackbarColor: 'snackbar/color',
        snackbarMsg: 'snackbar/message',
      }),
      snackbar: {
        get() {
          return this.$store.state.snackbar.snackbar;
        },
        set(val) {
          this.$store.dispatch('snackbar/update', {
            snackbar: val
          });
        }
      },
    },
  };
</script>
